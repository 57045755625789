import { r, createSender } from '@md/resources';

export const applications = {
  list: r('/applications/list/{?filters,order_by,limit,offset}'),
  simpleModeList: r('/bids/list/{?filters,order_by,limit,offset}'),
  addons: {
    statuses: r('/applications/addons/statuses/list/{?limit,offset}'),
    intermediary: r('/applications/addons/intermediary/list/{?filters,limit}'),
    intermediaryType: r('/applications/addons/intermediary-type/list/{?limit}'),
    touroperator: r('/applications/addons/touroperator/list/{?filters,limit}'),
    nights: r('/applications/addons/nights/list/{?filters,limit}'),
    agent: r('/applications/addons/agent/list/{?s,limit}'),
    tour: r('/applications/addons/tour/'),
  },
  filters: {
    list: r('/applications/filters/list/'),
    simpleModeList: r('/bids/filters/list/{?filters}'),
    available: r('/applications/filters/list/{?filters}'),
  },
  create: r('/applications/create/', createSender),
  calculate: r('/applications/calculate/', createSender),
  retrieve: r('/applications{/id}/retrieve/'),
  history: r('/applications{/id}/history/'),
  update: r('/applications{/id}/update/', createSender).config('method', 'PATCH'),
  dispute: r('/applications{/id}/dispute/', createSender).config('method', 'PATCH'),
  refuse: r('/applications{/id}/refuse/', createSender).config('method', 'PATCH'),
  delete: r('/applications{/id}/delete/', createSender),
  dmcList: r('/applications{/id}/dmc/list/'),
};
export const touroperatorApplications = {
  list: r('/touroperator-applications/list/{?filters,order_by,limit,offset}'),
  addons: {
    statuses: r('/touroperator-applications/addons/statuses/list/{?limit,offset}'),
    // intermediary: r('/touroperator-applications/addons/intermediary/list/{?filters,limit}'),
    // intermediaryType: r('/touroperator-applications/addons/intermediary-type/list/{?limit}'),
  },
  filters: {
    list: r('/touroperator-applications/filters/list/'),
    available: r('/touroperator-applications/filters/list/{?filters}'),
  },
  create: r('/touroperator-applications/create/', createSender),
  calculate: r('/touroperator-applications/calculate/', createSender),
  retrieve: r('/touroperator-applications{/id}/retrieve/'),
  // history: r('/touroperator-applications{/id}/history/'),
  update: r('/touroperator-applications{/id}/update/', createSender).config('method', 'PATCH'),
  // dispute: r('/touroperator-applications{/id}/dispute/', createSender).config('method', 'PATCH'),
  refuse: r('/touroperator-applications{/id}/refuse/', createSender).config('method', 'PATCH'),
  delete: r('/touroperator-applications{/id}/delete/', createSender),
};

export const booking = {
  addons: {
    payment: r('/booking/addons/payment/calculate/', createSender),
    validate: r('/booking/addons/payment/validate/', createSender),
    statuses: r('/booking/addons/statuses/list/{?limit,offset}'),
  },
  list: r('/booking/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/booking/filters/list/'),
    available: r('/booking/filters/list/{?filters}'),
  },
  aggregate: r('/booking/aggregate/{?filters}'),
  retrieve: r('/booking{/id}/retrieve/'),
  delete: r('/booking{/id}/delete/', createSender),
  update: r('/booking{/id}/update/', createSender).config('method', 'PATCH'),
  refuse: r('/booking{/id}/refuse/', createSender).config('method', 'PATCH'),
  dispute: r('/booking{/id}/dispute/', createSender).config('method', 'PATCH'),
  create: r('/booking/create/', createSender),
};

export const typo = {
  create: r('/typo/create/', createSender),
};

export const request = {
  create: r('/request/create/', createSender),
  list: r('/request/list/{?filters,order_by,limit,offset}'),
  update: r('/request{/id}/update/'),
};
